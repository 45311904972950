<template>
    <q-layout view="hHh lpR fFf">

        <q-page-container>
            <router-view />
        </q-page-container>
    </q-layout>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<script>
  export default {
    name: ""
  }
</script>
